<template>
  <div class="mt-5 mb-4">
    <hr/>
    <div class="item-top-area d-flex align-items-center">
      <div class="item-right mr-auto">
        <div class="switch-toggle">
          <span>✨ Style </span>
        </div>
      </div>
    </div>
    <div class="mt-3 input_icon_text">
      <div class="style_body d-flex align-items-center">
        <div :class="{'active_style':item.selected}" v-tooltip="item.name" class="ml-2 d-flex align-items-center"  v-for="(item,index) in styleList" @click="selectStyle(index)">
          <img class="img-fluid ml-1" width="20px" :src="item.image"/>
        </div>
        </div>
    </div>
  </div>
</template>
<script>
export default ({
  data () {
    return {
      styleObj: {
        style: ''
      },
      styleList: [
        {key: 'card-1', name: 'Card 1', selected: true, image: '/assets/img/bioLink/BioLinkIcons/card-1.png'},
        {key: 'card-2', name: 'Card 2', selected: false, image: '/assets/img/bioLink/BioLinkIcons/card-2.png'},
        {key: 'thumb-1', name: 'Thumb 1', selected: false, image: '/assets/img/bioLink/BioLinkIcons/thumb-1.png'},
        {key: 'thumb-2', name: 'Thumb 2', selected: false, image: '/assets/img/bioLink/BioLinkIcons/thumb-2.png'},
        {key: 'strip', name: 'Strip', selected: false, image: '/assets/img/bioLink/BioLinkIcons/strip.png'}
      ]
    }
  },
  created () {
    this.styleList.filter((item) => item.selected = item.key === this.styling)
  },
  props: [
    'styling'
  ],
  methods: {
    selectStyle (index) {
      this.styleList.filter((item, i) => item.selected = i === index)
      this.styleObj.style = this.styleList[index].key
      this.$emit('styleObj', this.styleObj)
    }
  },
  watch: {}
})
</script>

<style scoped lang="less">
.style_body {
  div {
    background: #eff4ff;
    border: 1px solid #eff4ff;
    padding: 4px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    box-shadow: 0 3px 6px #00000029;
    &:hover {
      border: 1px solid #3988fe;
    }
  }

  .active_style {
    border: 1px solid #3988fe !important;
  }
}
</style>
